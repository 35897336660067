import * as Yup from "yup";
import {
  emailRegExp,
  emailRegMsg,
  requiredEmailMsg,
  requiredMsg,
} from "../utils/form";
import { createOption } from "../utils/components";

export const formatCreateUserData = (data) => {
  const res = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
    authentication: {
      new_password: data.password,
    },
  };

  return [res];
};

export const COUNTRY_OPTIONS = [createOption("US", "United States")];

export const sign_up_schema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegExp, emailRegMsg)
    .email(requiredEmailMsg())
    .required(requiredEmailMsg("Email Address")),
  password: Yup.string().min(7).required(requiredMsg("Password")),
  confirm_password: Yup.string()
    .label("confirm password")
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required(requiredMsg("Password")),
  first_name: Yup.string().required(requiredMsg("first name")),
  last_name: Yup.string().required(requiredMsg("last name")),
});

export const initialValues = {
  email: "",
  password: "",
  confirm_password: "",
  first_name: "",
  last_name: "",
  phone: "",
};

export const login_validation_schema = Yup.object().shape({
  email: Yup.string()
    .matches(emailRegExp, emailRegMsg)
    .email(requiredEmailMsg())
    .required(requiredEmailMsg("Email Address")),
  password: Yup.string().min(7).required(requiredMsg("Password")),
});

export const login_form_initial_values = {
  email: "",
  password: "",
};
