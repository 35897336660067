import React from "react";
import Layout from "../layout/Layout";
import TextField from "../components/form/TextField";
import { useFormik } from "formik";
import { getFormError } from "../utils/form";
import { sign_up_schema, initialValues } from "../utils/user";
import useCreateAccount from "../hooks/customer/useCreateAccount";
import Button from "../components/form/button/Button";

const CreateAccount = () => {
  const { createCustomerAccount } = useCreateAccount();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: sign_up_schema,
    onSubmit: (values) => {
      createCustomerAccount(values);
    },
  });

  return (
    <Layout>
      <div className="page-wrapper">
        <div className="page-heading-section text-center page-block">
          <h1 className="page-title">New Account</h1>
        </div>
      </div>

      <div className="container">
        <form
          className="form create-account-form form-mid"
          onSubmit={formik.handleSubmit}
        >
          <div className="form-row form-row--half">
            <div className="form-field">
              <TextField
                id={"customer-first-name"}
                name={"first_name"}
                label={"First Name"}
                labelFor={"first-name"}
                type={"input"}
                onChange={formik.handleChange}
                touched={formik.touched["first_name"]}
                error={getFormError(formik.errors, "first_name")}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-last-name"}
                name={"last_name"}
                label={"Last Name"}
                labelFor={"last-name"}
                type={"input"}
                onChange={formik.handleChange}
                error={getFormError(formik.errors, "last_name")}
                touched={formik.touched["last_name"]}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id="customer-email"
                label={"Email Address"}
                labelFor={"Email Address"}
                name={"email"}
                error={getFormError(formik.errors, "email")}
                type={"email"}
                onChange={formik.handleChange}
                touched={formik.touched["email"]}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id="customer-password"
                name={"password"}
                label={"Password"}
                htmlFor={"password"}
                type={"password"}
                onChange={formik.handleChange}
                touched={formik.touched["password"]}
                error={getFormError(formik.errors, "password")}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-confirm-password"}
                name={"confirm_password"}
                label={"Confirm Password"}
                labelFor={"confirm-password"}
                type={"password"}
                onChange={formik.handleChange}
                error={getFormError(formik.errors, "confirm_password")}
                touched={formik.touched["confirm_password"]}
                required
              />
            </div>

            <div className="form-field">
              <TextField
                id={"customer-phone"}
                name={"phone"}
                label={"Phone Number"}
                labelFor={"phone-number"}
                type={"input"}
                onChange={formik.handleChange}
                error={getFormError(formik.errors, "phone")}
              />
            </div>
          </div>

          <div className="form-actions text-center">
            <Button type="submit" className="button" disabled={!formik.isValid}>
              Create Account
            </Button>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default CreateAccount;
