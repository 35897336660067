import { navigate } from "gatsby";
import { useState } from "react";
import Api from "../../services/Api";
import { formatCreateUserData } from "../../utils/user";
import useToastify from "../ui/useToastify";

function useCreateAccount() {
  const [isLoading, setLoading] = useState(false);
  const { toastMessage } = useToastify();
  const createCustomerAccount = async (data) => {
    try {
      // Below url has type query params that is only for SWR to identify url uniquely.
      // That is not a valid query params for Bigcommerce.
      const url = `${process.env.NETLIFY_API_BASE_ADURL}/bc/api?type=create_account`;
      const body = {
        url: "v3/customers",
        query_params: {},
        method: "post",
        body: formatCreateUserData(data),
      };
      setLoading(true);
      const res = await createAccount(url, body);

      if (res.status === 422) {
        toastMessage("error", "This email id is already in use.");
      }

      if (res.status === 200) {
        navigate("/account-success");
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  return {
    createCustomerAccount,
    isLoading,
  };
}

async function createAccount(url, body) {
  const response = await Api.post(url, body);
  return response?.data ? response.data : response;
}

export default useCreateAccount;
